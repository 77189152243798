import React from 'react';
import { Link } from 'react-router-dom';
import LiveChat from './LiveChat';

const Home = () => {
  return (
    <div className='LiveChat'>
        <header className="header">
        <div className="logo-container">
          <img
            src="https://demo.healfate.com/static/media/image.ab9b323701b5386dfc75.png"
            alt="Logo"
          />
        </div>
      </header>
      <h1>Welcome to TalkndHeal</h1>
      <div>
        <Link to="/livechat">
          <button>Go to LiveChat</button>
        </Link>
        <Link to="/astrochat">
          <button>Go to Astro-User Chat</button>
        </Link>
      </div>
      <footer className="footer">
        <p>© All copyrights reserved 2024 Talk nd Heal | Powered By Immortal Varta</p>
      </footer>
    </div>
  );
}

export default Home;