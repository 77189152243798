import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home"; // Import Home component

const LiveChat = lazy(() => import("./LiveChat")); // Lazy load LiveChat component
const AstroChat = lazy(() => import("./AstroChat")); // Lazy load AstroChat component

function App() {
  return (
    <Router>
      <div className="App">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/livechat" element={<LiveChat />} />
            <Route path="/astrochat" element={<AstroChat />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;